import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import BlogsImg from "./BlogsImg";
import { Fade } from "react-reveal";
import "./MentorshipComponent.css";
import { style } from "glamor";

function MentorshipComponent(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  return (
    <div className="contact-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-contact">
        <Fade bottom duration={1000} distance="40px">
          <div className="blog-heading-div">
            <div className="blog-heading-text-div">
              <h1 className="blog-heading-text" style={{ color: theme.text }}>
                Mentorluk
              </h1>
              <p
                className="blog-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                  Yazılımla ilgileniyor ancak nereden başlayacağınızı bilmiyorsanız, bir yazılım projesi ile ilgili destek istiyorsanız, veya yazılım hakkında sorularınızı cevaplayacak birine ihtiyaç duyuyorsanız, aşağıdaki butonu kullanarak mentee başvurusunda bulunabilirsiniz.
              </p>
              
              <div className="blogsite-btn-div">
                <a {...styles} className="general-btn" href="https://forms.gle/dfPQoxKYafZFQKRn6" target="_blank">
                  Mentorluk Başvurusu
                </a>
              </div>
            </div>
            <div className="blog-heading-img-div">
              <BlogsImg theme={theme} />
            </div>
          </div>
        </Fade>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default MentorshipComponent;
