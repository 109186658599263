/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  //googleTrackingID: "UA-165346764-2",
};

//Home Page
const greeting = {
  title: "Merhaba 👋.",
  title2: "Yazılım Abi",
  logo_name: "Yazılım Abi",
  nickname: "Eren / Yazılım Abi",
  full_name: "Yazılım Abi",
  subTitle: "Back End Web Geliştirici, Oyun Geliştirici, Yazılım Eğitmeni ve Full Time Liseliyim 😅",
  resumeLink:
    "https://docs.google.com/document/d/1A_jWRG74Rst427tg1izLa6vRXclS9_9F856jWJPJlDY/edit?usp=sharing",
  mail: "mailto:erengrl3854@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/yazilimabi",
  linkedin: "https://www.linkedin.com/in/eren-gurel/",
  gmail: "mailto:erengrl3854@gmail.com",
  instagram: "https://www.instagram.com/eren.cpp/",
};

const skills = {
  data: [
    {
      title: "Oyun ve Yazılım Geliştiricisi",
      fileName: "a",
      skills: [
        "⚡ Unity ve Godot ile interaktif ve eğlenceli oyunların geliştirilmesi",
        "⚡ C#, C++, Python ve Javascript ile günlük hayatı kolaylaştıran uygulamaların geliştirilmesi",
        "⚡ İnsan gücü gerektirmeyen işlerin otomatikleştiren uygulamaların geliştirilmesi",
      ],
      softwareSkills: [
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "simple-icons:csharp",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Windows",
          fontAwesomeClassname: "simple-icons:windows",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        }
      ],
    },
    { 
      title: "Back End Web Geliştiricisi",
      fileName: "BackEndImg",
      skills: [
        "⚡ ReactJS, NodeJS ve PHP ile hızlı ve verimli web uygulamaları geliştirilmesi",
        "⚡ MySQL ve MongoDB ile verilerin güvenli bir şekilde saklanması",
        "⚡ REST API sunucuları ile yazılım - yazılım köprüsünün kurulması",
        "⚡ Uygun algoritmalar ve kütüphaneler ile optimize ve güvenli veri akışını sağlanması",
      ],
      softwareSkills: [
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "IT Sorumlusu",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Yazılım projelerinin güvenliğinin ve güncel olmasının sağlanması",
        "⚡ Sunucu ve bilgisayarların bakımının yapılması",
        "⚡ Olağanüstü durumlarda (Siber Saldırı, Sunucu Çökmesi) sistem devamlılığının sağlanması",
        "⚡ Teknik destek ve teknolojik yardım",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "GitHub",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
    {
      title: "Yazılım Mentorluğu",
      fileName: "MentorshipImg",
      skills: [
        "⚡ Yazılım alanında tecrübesi bulunmayan ancak öğrenmek isteyen kişilere mentorluk",
        "⚡ Lise Bilgisayar Bilimi dersi için temel yazılım bilgisi eğitimi",
        "⚡ Üniversite öğrencileri için proje ve ders desteği",
        "⚡ Mentee başvurusu yapmak istiyorsanız Mentorluk sekmesinden başvuru yapabilirsiniz.",
      ],
      softwareSkills: [/*
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },*/
      ],
    }
  ],
};

const degrees = {
  degrees: [
    {
      title: "Lise Eğitimi",
      subtitle: "Kabataş Erkek Lisesi",
      logo_path: "kabataslogo.png",
      alt_name: "KEL",
      duration: "2019 - Günümüz",
      descriptions: [
        "⚡ Türkiye Derecesi ile girmeye hak kazandığım lisemde ortaöğretimime devam etmekteyim.",
        "⚡ Kabataş Erkek Kodlama Ve Yazılım Takımı'nın (KELKOD) kuruculuğunu ve kaptanlığını yapmaktayım.",
        "⚡ Kabataş Erkek Rehberlik Sistemi ve Kabataş Erkek Yönetim Sistemi'nin (KEYS) geliştiriciliğini yapmaktayım.",
        "⚡ Kabataş MUN ve Tarih Kulüplerinde IT Departmanı Sorumlusu olarak görev yapmakla beraber okulumuz konferanslarının websitelerinin kurulmasından sorumluyum.",
      ],
      website_link: "https://kabataserkeklisesi.meb.k12.tr/",
    },
    {
      title: "Bilim İnsanı Destekleme Ve Yetiştirme Programı",
      subtitle: "Tübitak",
      logo_path: "tubitaklogo.png",
      alt_name: "Tübitak",
      duration: "2021 - Günümüz",
      descriptions: [
        "⚡ Tübitak Ulusal Bilim Olimpiyatları'nın bilgisayar dalında Türkiye'de derecesi elde ederek programa katılmaya hak kazandım.",
        "⚡ Toplam 70 Saat \"İleri Seviye Algoritma ve Veri Yapıları\" kampına katıldım.",
        "⚡ Aktif olarak Tübitak 2248 Mentorluk programından yararlanmaktayım.",
      ],
      website_link: "https://www.tubitak.gov.tr/",
    },
    {
      title: "Temel Fikirlerden Temel Bilimlere Programı",
      subtitle: "İstanbul İl Milli Eğitim Müdürlüğü",
      logo_path: "meblogo.png",
      alt_name: "İstanbul İl Milli Eğitim Müdürlüğü",
      duration: "2021 - Günümüz",
      descriptions: [
        "⚡ İstanbul Bilim Olimpiyatları Bilgisayar dalında ulusal başarı elde ederek programa katılmaya hak kazandım.",
        "⚡ Toplamda 300 Saat \"İleri Seviye Algoritma ve Veri Yapıları\" ve \"İleri Seviye Sonlu Matematik\" eğitimlerine katıldım.",
        "⚡ Aktif olarak İstanbul Erkek Lisesi'nde gerçekleştirilen olimpiyat eğitimlerine katılmaktayım",
      ],
      website_link: "https://istanbul.meb.gov.tr/",
    }
  ],
};

const certifications = {
  certifications: [
    {
      title: "Makine Öğrenmesi ve Yapay Zeka Eğitimi",
      subtitle: "Sabancı Üniversitesi",
      logo_path: "sabancilogo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "Sabancı Üniversitesi",
      // color_code: "#2AAFED",
      color_code: "#C5E2EE",
    },
    {
      title: "Sanal Gerçeklik Tasarımı Atölyesi",
      subtitle: "Özyeğin Üniversitesi",
      logo_path: "ozyeginlogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
      alt_name: "Özyeğin Üniversitesi",
      color_code: "#E2405F",
    },
    {
      title: "Elements Of Artificial Intelligence Course",
      subtitle: "Helsinki University",
      logo_path: "helsinkilogo.png",
      certificate_link:
        "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
      alt_name: "Helsinki University",
      // color_code: "#F6B808",
      color_code: "#fffbf3",
    },
    {
      title: "Algoritma ve Veri Yapıları İleri Seviye",
      subtitle: "Bilgi Teknolojileri Ve İletişim Kurumu",
      logo_path: "btklogo.png",
      certificate_link:
        "https://drive.google.com/file/d/12uAdjQC5LfrB1ODdxqIY181ugyyQhl-I/view?usp=sharing",
      alt_name: "Bilgi Teknolojileri Ve İletişim Kurumu",
      color_code: "#C5E2EE",
    },
    {
      title: "Python For Data Science",
      subtitle: "IBM",
      logo_path: "ibmlogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
      alt_name: "IBM",
      color_code: "#fffbf3",
    },
    {
      title: "JavaScript Algorithms and Data Structures",
      subtitle: "FreeCodeCamp",
      logo_path: "fcclogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
      alt_name: "FreeCodeCamp",
      color_code: "#7A7A7A",
    },
    {
      title: "Mentor",
      subtitle: "Global AI Hub",
      logo_path: "globalaihublogo.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "Global AI Hub",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    {
      title: "Boğaziçi Game Jam Sertifikası",
      subtitle: "Boğaziçi Üniversitesi Bilişim Kulübü",
      logo_path: "compeclogo.png",
      certificate_link:
        "https://verified.sertifier.com/en/verify/77921630943446",
      alt_name: "Boğaziçi Üniversitesi Bilişim Kulübü",
      // color_code: "#f36c3d",
      color_code: "#C5E2EE",
    },
    {
      title: "Jamination 4 Katılım Sertifikası",
      subtitle: "İTÜ Oyun Tasarlama Ve Geliştirme Kulübü",
      logo_path: "ituotglogo.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "İTÜ Oyun Tasarlama Ve Geliştirme Kulübü",
      // color_code: "#f36c3d",
      color_code: "#b190b0",
    },
    {
      title: "Frost Jam 4 Katılım Sertifikası",
      subtitle: "İTÜ Oyun Tasarlama Ve Geliştirme Kulübü",
      logo_path: "ituotglogo.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "İTÜ Oyun Tasarlama Ve Geliştirme Kulübü",
      // color_code: "#f36c3d",
      color_code: "#b190b0",
    },
    {
      title: "Tübitak Bilim Kampı Katılım Sertifikası",
      subtitle: "Tübitak",
      logo_path: "tubitaklogo.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "Tübitak",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

const awards = {
  awards: [
    {
      title: "Türkiye Derecesi",
      subtitle: "Tübitak 30. Ulusal Bilim Olimpiyatları",
      logo_path: "tubitaklogo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "Tübitak 30. Ulusal Bilim Olimpiyatları",
      // color_code: "#2AAFED",
      color_code: "#fffbf3",
    },
    {
      title: "Gümüş Madalya",
      subtitle: "İstanbul Bilim Olimpiyatları 2021",
      logo_path: "meblogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
      alt_name: "İstanbul Bilim Olimpiyatları 2021",
      color_code: "#fffbf3",
    },
    {
      title: "Dr. Merih Ergin Ödülü",
      subtitle: "Kabataş Erkek Lisesi Eğitim Vakfı 2022",
      logo_path: "kelvakiflogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
      alt_name: "Kabataş Erkek Lisesi Eğitim Vakfı",
      color_code: "#fffbf3",
    },
    {
      title: "Türkiye Derecesi",
      subtitle: "Siber Zeka Siber Güvenlik Yarışması",
      logo_path: "ddologo.png",
      certificate_link:
        "https://drive.google.com/file/d/12uAdjQC5LfrB1ODdxqIY181ugyyQhl-I/view?usp=sharing",
      alt_name: "Siber Zeka Siber Güvenlik Yarışması",
      color_code: "#fffbf3",
    },
    {
      title: "Türkiye Üçüncülüğü",
      subtitle: "CODEFEST 2022",
      logo_path: "uaalogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
      alt_name: "CODEFEST 2022",
      color_code: "#fffbf3",
    },
    {
      title: "Bronz Madalya",
      subtitle: "İstanbul Bilim Olimpiyatları 2020",
      logo_path: "meblogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
      alt_name: "İstanbul Bilim Olimpiyatları 2020",
      color_code: "#fffbf3",
    },
    {
      title: "Dr. Merih Ergin Ödülü",
      subtitle: "Kabataş Erkek Lisesi Eğitim Vakfı 2022",
      logo_path: "kelvakiflogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
      alt_name: "Kabataş Erkek Lisesi Eğitim Vakfı",
      color_code: "#fffbf3",
    },
    {
      title: "Finalist",
      subtitle: "Bahçeşehir Koleji 2. Ulusal Bilim Olimpiyatları",
      logo_path: "bahcesehirlogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
      alt_name: "Kabataş Erkek Lisesi Eğitim Vakfı",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Tecrübelerim",
  subtitle: "İş deneyimlerim ve daha fazlası",
  description:
    "Kendimi geliştirmenin yanı sıra öğrendiklerim ile insanlara yardımcı olmak amacıyla bir çok faaliyette bulundum. Ayrıca çeşitli organizasyonlarda da rol alarak sosyal açıdan da kendimi geliştirmekteyim.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "İş Deneyimlerin",
      experiences: [
        {
          title: "Full Stack Web Geliştirici",
          company: "Kabataş Erkek Lisesi",
          company_url: "https://kabataserkeklisesi.meb.k12.tr/",
          logo_path: "kabataslogo.png",
          duration: "Aralık 2022 - Günümüz",
          location: "Gönüllü",
          description: `Kabataş Erkek Lisesi'nin rehberlik ihtiyaçlarını teknoloji ile kolaylaştırmak amacıyla gönüllü olarak Kabataş
          Erkek Lisesi Rehberlik Randevu Sistemi'ni geliştirmekteyim. Kabataş Erkek Lisesi Rehberlik Randevu Sistemi günümüzde aktif olarak kullanılmakla birlikte
          toplamda 2000'den fazla kişiye ve 50'den fazla okula randevu verilmesini sağlamıştır. Sistem web tabanlı olup; NodeJS, ReactJS ve MongoDB teknolojileri ile geliştirilmektedir.
          `,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#ee3c26",
        },
        {
          title: "Yazılım Mentoru",
          company: "Global AI Hub",
          company_url: "https://globalaihub.com/",
          logo_path: "globalaihublogo2.png",
          duration: "Ağustos 2022 - Ekim 2022",
          location: "Gönüllü",
          description: `Global AI Hub tarafından başlatılan 10million.ai projesi kapsamında gönüllü olarak yapay zeka ile ilgilenen kişilere
          yazılım mentorluğu yaptım. Koç Holding sponsorluğunda düzenlenen Derin Öğrenme Bootcamp'te mentor olarak görev aldım. 
          `,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        {
          title: "Full Stack Web Geliştirici",
          company: "WEVisionary",
          company_url: "https://www.linkedin.com/company/we-visionary/",
          logo_path: "wevlogo.png",
          duration: "Nisan 2021 - Haziran 2021",
          location: "Yarı Zamanlı",
          description: `WEVisionary topluluğu altında bir girişim projesi üzerinde Full Stack Web Geliştirici olarak çalıştım. NodeJS, ReactJS ve MySQL teknolojileri
          kullanılarak web tabanlı bir sosyal medya uygulaması geliştirdik.
          `,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        {
          title: "Back End Web Geliştirici",
          company: "Kabataş Erkek Lisesi",
          company_url: "https://kabataserkeklisesi.meb.k12.tr/",
          logo_path: "kabataslogo.png",
          duration: "Mayıs 2020 - Şubat 2021",
          location: "Gönüllü",
          description: `Kabataş Erkek Lisesi'nin pandemi sürecindeki eğitim ihtiyaçlarını karşılamak amacıyla başlatılan Kabataş Erkek Lisesi Yönetim Sistemi Projesi'nde 
          Back End Web Geliştirici olarak görev aldım. PHP, jQuery ve MySQL teknolojileri ile geliştirdiğimiz sistemde açık kaynak kodlu projelerden de destek aldık. Sistemi 1 yılı aşkın bir pandemi sürecinde
          900 öğrenci ve 70 öğretmen aktif olarak eğitim öğretim sürecini devam ettirmek amacıyla kullandı.
          `,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Organizasyon Rollerim",
      experiences: [
        {
          title: "Kurucu ve Kaptan",
          company: "Kabataş Erkek Lisesi Yazılım ve Kodlama Takımı (KELKOD)",
          company_url: "",
          logo_path: "kelkodlogo.png",
          duration: "2021 Aralık - Günümüz",
          location: "",
          description:
            `Kabataş Erkek Lisesi'nin bütün yazılım ihtiyaçlarını karşılamak ve okulun ulusal ve uluslararası kodlama yarışmalarda temsil etmek amacıyla 2021 - 2022 eğitim öğretim yılında tarafımca kuruldu. Kaptanlık görevini üstlendiğim KELKOD, günümüzde aktif olarak
            yarışmalara katılmakta ve okulumuz teknolojik ihtiyaçlarına yazılımsal çözümler geliştirmektedir.`,
          color: "#D83B01",
        },
        {
          title: "Kulüp Başkanı",
          company: "Kabataş Erkek Lisesi Yazılım Kulübü",
          company_url: "",
          logo_path: "kelyazilimlogo.png",
          duration: "Eylül 2021 - Eylül 2022",
          location: "",
          description:
            `Kabataş Erkek Lisesi'ndeki öğrencilerin yazılıma olan ilgisinin arttırılması ve gerekli yazılım eğitimlerinin verilmesini sağlamak amacıyla kurulan Kabataş Erkek Lisesi Yazılım Kulübü'ne 2021 - 2022 eğitim öğretim yılında başkan seçildim.
            Başkanlık sürecimde üniversite gezileri, eğitici konferanslar ve 8 haftalık yazılıma giriş eğitimi düzenleyerek 25 kişilik kulübü yıl sonunda 180 kişiye çıkardım. 2022 - 2023 eğitim öğretim yılında
            ulusal yarışmalara odaklanabilmek amacıyla başkanlığımı okuldaki arkadaşlarıma devrettim.`,
          color: "#4285F4",
        },
        {
          title: "IT Departmanı Sorumlusu",
          company: "Kabataş Model United Nations Kulübü",
          company_url: "https://kabatasmodelun.org/",
          logo_path: "kmunlogo.png",
          duration: "Eylül 2022 - Günümüz",
          location: "",
          description:
            "Kabataş Erkek Lisesi Model United Nations Kulübü'nün teknolojik ihtiyaçlarına çözüm bulmak amacıyla IT Departmanı Sorumlusu ve Yönetim Kurulu Üyesi olarak görev yapmaktayım.",
          color: "#D83B01",
        },
        {
          title: "IT Departmanı Sorumlusu",
          company: "Kabataş Tarih Kulübü",
          company_url: "https://kabatastarih.com/",
          logo_path: "keltarih.png",
          duration: "Eylül 2022 - Günümüz",
          location: "",
          description:
            "Kabataş Erkek Lisesi Tarih Kulübü'nün teknolojik ihtiyaçlarına çözüm bulmak amacıyla IT ve Departman Sorumlusu olarak görev yapmaktayım.",
          color: "#D83B01",
        },
        {
          title: "Öğrenci Geliştirici Programı Üyesi",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github.png",
          duration: "Eylül 2021 - Günümüz",
          location: "",
          description:
            "Açık Kaynak Kodlu projeleri desteklemek amacıyla 2021 yılından beri GitHub Öğrenci Geliştirici Programı kapsamında yazılım geliştiriyorum.",
          color: "#040f26",
        },
        {
          title: "Mentor Ve Core Member",
          company: "Global AI Hub",
          company_url: "https://globalaihub.com/",
          logo_path: "globalaihublogo2.png",
          duration: "Mart 2022 - Günümüz",
          location: "",
          description: `Global AI Hub tarafından başlatılan 10million.ai projesine Core Member olarak katkı sağlıyorum. Bu kapsamda mentorluk, eğitimlere katkı sağlamak ve teknik yardım gibi 
          faaliyetlerde bulunuyorum. 
          `,
          color: "#4285F4",
        },
        {
          title: "Kulüp Üyesi",
          company: "İTÜ Oyun Tasarlama Ve Geliştirme Kulübü",
          company_url: "https://otg.itu.edu.tr/",
          logo_path: "ituotglogo.png",
          duration: "Haziran 2021 - Günümüz",
          location: "",
          description:
            "Oyun tasarlama ile ilgilenenleri tek bir çatı altında buluşturmak amacıyla oluşan İTÜ Oyun Tasarlama Ve Geliştirme Kulübü'ne üyeyim. Kulüp içerisinde düzenlenen bir çok eğitime ve oyun geliştirme yarışmasına katılmış bulunmaktayım.",
          color: "#5a900f",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projelerim",
  description:
    "Okulumuzun çeşitli yazılım ihtiyaçları, ulusal çaptaki proje yarışmaları ve çeşitli girişim projeleri geliştirilmesi gibi bir çok projeye imza atmış bulunmaktayım.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Bana Ulaşın",
    profile_image_path: "eren.png",
    description:
      "Aşağıdaki iletişim seçeneklerini kullanarak bana ulaşabilirsiniz. Mümkün olan en kısa sürede size dönüş yapacağım.",
  }
};

const projects = {
  data: [
    {
      id: "0",
      name: "Kabataş Erkek Yönetim Sistemi",
      url: "",
      description:
        `Kabataş Erkek Lisesi'nin pandemi sürecinde eğitim ihtiyaçlarını karşılamak amacıyla başlatıldı. Sistemi 1 yılı aşkın bir pandemi sürecinde
        900 öğrenci ve 70 öğretmen aktif olarak eğitim öğretim sürecini devam ettirmek amacıyla kullandı.`,
      languages: [
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "jQuery",
          iconifyClass: "logos-jquery",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      id: "1",
      name: "Kabataş Erkek Rehberlik Sistemi",
      url: "",
      description:
        `Kabataş Erkek Lisesi'nin rehberlik ihtiyaçlarını karşılamak amacıyla geliştirdiğim bir projedir. Kabataş Erkek Lisesi Rehberlik Randevu Sistemi günümüzde aktif olarak kullanılmakla birlikte
        toplamda 2000'den fazla kişiye ve 50'den fazla okula randevu verilmesini sağlamıştır.`,
      languages: [
        {
          name: "Node JS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "React JS",
          iconifyClass: "logos-react",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos-mongodb",
        },
      ],
    },
    {
      id: "13",
      name: "Bagaj Takip Sistemi",
      url: "",
      description:
        "Türk Hava Yolları Hackathonu projesi olarak 3 kişilik bir ekip tarafından kodlandı. Havaalanlarında kaybolan bagajların takibinin yapılması için geliştirildi.",
      languages: [
        {
          name: "Node JS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "React JS",
          iconifyClass: "logos-react",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos-mongodb",
        },
      ],
    },
    {
      id: "2",
      name: "Tembel Adam",
      url: "",
      description:
        "Ulusal Mağara Oyun Geliştirme yarışması için 48 saat içerisinde KELKOD ekibi tarafından geliştirildi. Tembel Adam oyunu, 450 takım arasından ilk 50'ye girerek finalist olmaya hak kazandı",
      languages: [
        {
          name: "Unity",
          iconifyClass: "skill-icons:unity-light",
        },
        {
          name: "C#",
          iconifyClass: "vscode-icons:file-type-csharp2",
        },
      ],
    },
    {
      id: "3",
      name: "Kenpar's Private Chat App",
      url: "",
      description:
        "Python kullanılarak geliştirilmiştir. Sunucu iletişimi, kırılamaz bir güvenlik sistemi oluşturulması amacıyla çift katmanlı olarak şifrelenmektedir. Bu durum mesajı, gönderilen kişi dışında sunucu da dahil olmak üzere kimsenin mesajı okuyamamasını sağlamaktadır.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        }
      ],
    },
    {
      id: "4",
      name: "Automated Mail Bot",
      url: "",
      description:
        "Konferans, üye alımı gibi durumlarda kullanılmak üzere geliştirilmiş bir e-posta otomasyon projesidir. Çeşitli veri formatlarından kişi verilerinin alınması ile e-postaların dinamik bir şekilde otomatik ve hızlı gönderimini sağlar.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
      ],
    },
    {
      id: "4",
      name: "Project Kedy",
      url: "",
      description:
        "NodeJS ile geliştirilmiş bir Instagram yazılımı projesidir. Fotoğraf arşivinden çekilen fotoğrafların kullanıcının belirlediği zaman periyotlarında otomatik olarak paylaşmasını sağlamaktadır.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
      ],
    },
    {
      id: "4",
      name: "Yazılım Botu",
      url: "",
      description: "Yazılım topluluklarında kullanılması amacıyla geliştirilmiş bir Discord yazılımıdır. Çeşitli API'lardan alınan bilgiler yardımıyla güncel haberlerin toplandığı, kayıt gibi işlemlerin otomatize edildiği ve topluluk moderatorlerine yardımcı olmak amacıyla geliştirilen bir projedir.",
      languages: [
        {
          name: "NodeJs",
          iconifyClass: "logos-nodejs",
        },
      ],
    },
    {
      id: "5",
      name: "VR Golf Oyunu",
      url: "",
      description:
        "Özyeğin Üniversitesi Sanal Gerçeklik atölyesinde geliştirdiğim bir oyun projesidir.",
      languages: [
        {
          name: "Unity",
          iconifyClass: "skill-icons:unity-light",
        },
        {
          name: "C#",
          iconifyClass: "vscode-icons:file-type-csharp2",
        },
        {
          name: "Sanal Gerçeklik",
          iconifyClass: "bi:badge-vr",
        }
      ],
    },/*
    {
      id: "6",
      name: "Automate Attendace",
      url: "https://github.com/harikanani/Node_Python",
      description: "Automation of Online Attendance using node js and python",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "7",
      name: "Automate Discord Bot",
      url: "https://github.com/harikanani/AutomateDiscordBot",
      description:
        "A Discord Bot to send Automatic messages to serer channel in regular time difference.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Python Selenium",
          iconifyClass: "logos-selenium",
        },
        {
          name: "Chromium Browser",
          iconifyClass: "openmoji-chromium",
        },
      ],
    },
    {
      id: "8",
      name: "Flask Blog",
      url: "https://github.com/harikanani/flask_blog",
      description: "A Simple Blog Web Application made using Flask Framework",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Flask",
          iconifyClass: "cib-flask",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
      ],
    },
    {
      id: "9",
      name: "Netflix top series",
      url: "https://github.com/harikanani/netflix-top-series",
      description: "List of Top Netflix Series which is deployed to vercel.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
      ],
    },
    {
      id: "10",
      name: "COVID-19 Tracker",
      url: "https://github.com/harikanani/Covid19TrackerReact",
      description:
        "Simple Covid-19 Tracker made using React and deployed to Vercel.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
      ],
    },
    {
      id: "11",
      name: "Food Order Static Website",
      url: "https://github.com/harikanani/food-order-website",
      description:
        "A simple static website related to food restaurants service. this is reasponsive as well.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
      ],
    },
    {
      id: "12",
      name: "NFT Launchpad",
      url: "https://deliquescent-cents.000webhostapp.com/",
      description: "NFT Launchpad crypto site portfolio",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "logos-html-5",
        },
        {
          name: "CSS3",
          iconifyClass: "logos-css-3",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
      ],
    },*/
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  awards,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
